$n: 10;
.aboutCard {
  border-radius: 15px;
  padding: 16px;
  width: 100%;

  @media (max-width: 959px) {
    @for $i from 0 through $n {
      &:nth-child(#{$i}) {
        transform: translateY($i * -20px);
        padding-bottom: 40px;
      }
    }

    &:last-child {
      padding-bottom: 24px;
      margin-bottom: -60px;
    }
  }

  @media (min-width: 960px) {
    width: 320px;
    padding: 16px;
  }


  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    color: #000;
    font-size: 24px;
    font-weight: 600;
  }

  .icon {
    margin-left: 20px;
  }

  .text {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin-top: 13px;
  }
}