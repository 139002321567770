@use 'src/assets/styles/stub' as *;

.bestCredits {
  margin: 0;
  padding:  77px 20px 0;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 960px) {
    width: 980px;
    margin: 108px auto;
    padding: 0;
  }

  .title {
    margin-bottom: 24px;

    @media (max-width: 960px) {
      margin-bottom: 16px;
    }
  }

  .providers {
    margin-top: 10px;
    padding:0;
    display: grid;
    gap: 8px;
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);

    @media (min-width: 960px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    .banner {
      grid-column: 1 / 7;

      @media (max-width: 1000px) {
        grid-column: 1 / 3;
      }
    }


    .stub {
      @include stub;

      flex-shrink: 0;
      flex-basis: 155px;
      width: 155px;
      height: 299px;
      border-radius: 10px;


      @media (min-width: 320px) and (max-width: 360px){
        min-width: 134px;
        width: 100%;
      }

      @media (min-width: 360px) and (max-width: 960px) {
        min-width: 155px;
        width: 100%;
      }
    }

    .stubBunner {
      @include stub;

      width: 100%;
      height: 80px;
      border-radius: 10px;
    }
  }
}


