.searchFormTitle {
  margin-top: 28px;
  padding: 20px 15px 18px;
  border-radius: 10px;

  .text {
    margin-top: 19px;
    width: 420px;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 960px) {
      font-size: 14px;
      font-weight: 400;
      margin-top: 4px;
    }
  }

  .form {
    margin-top: 19px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 10px;
  }

  .searchInput {
    padding: 16px;
    height: 50px;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Gilroy, sans-serif;
    border: none;

    &::placeholder {
      color: black;
    }

    &:focus {
      outline: none;
    }
  }
}

.titleContainer {
  display: flex;
  align-items: center;

  h1 {
    display: inline-block;
  }

  @media (max-width: 960px) {
    height: 25px;
  }
}

.subtitle {
  margin-top: 5px;
}

.subtext {
  color: #57514D;
  font-size: 14px;
  font-weight: 400;
  margin-top: 15px;
}

.title {
  @media (min-width: 960px) {
    height: 45px;
  }
}