@use 'src/assets/styles/stub' as *;

.textField {
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #999;
  }

  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }
}
