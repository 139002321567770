@use 'src/assets/styles/stub' as *;

.bestOffers {
  .title {
    margin-bottom: 10px;

    @media (min-width: 960px) {
      padding-left: 0;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .cards {
    margin-top: 20px;
    gap: 10px;
    overflow: hidden;
  }

  .flexContainer {
    display: flex;
    gap: 10px;
  }

  .offerContainer {
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    height: 317px;
  }

  .stub {
    @include stub;

    flex-shrink: 0;
    flex-basis: 155px;
    width: 155px;
    height: 254px;
    border-radius: 10px;
  }

  .link {
    font-size: 18px;
    color: #35B879;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
  }
}