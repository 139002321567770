.snizimMagazine {
  .title {
    padding-left: 15px;
    margin-bottom: 10px;

    @media (min-width: 960px) {
      font-size: 36px;
      font-weight: 700;
      padding-left: 0;
      margin-bottom: 20px;
    }
  }

  .controls {
    @media (min-width: 960px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .moreButton {
    flex-shrink: 1;
    width: 320px;
    display: none;

    @media (min-width: 960px) {
      display: block;
    }
  }

  .articles {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 24px;

    @media (min-width: 960px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .link {
    margin-top: 20px;
    border-radius: 10px;
    line-height: 36px;
    height: 36px;

    @media (min-width: 960px) {
      display: none;
    }
  }
}