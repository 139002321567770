.lookForCard {
  box-sizing: border-box;
  border-radius: 10px;
  background: #F0F0F0;
  padding: 15px;
  width: 252px;
  min-height: 270px;
  flex-shrink: 0;

  @media (min-width: 960px) {
    width: 100%;
  }

  .title {
    font-size: 15px;

    @media (min-width: 960px) {
      font-size: 16px;
    }
  }

  .linksContainer {
    margin-top: 20px;
  }

  .link {
    display: block;
    color: #453854;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;

    @media (min-width: 960px) {
      font-size: 14px;
    }

    &:visited {
      color: #453854;
    }

    &:not(:first-child) {
      margin-top: 12px;
    }
  }
}