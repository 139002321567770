
.headerReg {
  padding: 20px 20px 21px;

  @media (min-width: 960px) {
      padding: 0;
  }

  .container {
    margin-top: 24px;
  }

  .flexContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }

  .subTitle {
    color: #DFEB81;
    font-size: 15px;
    font-weight: 600;
  }
}