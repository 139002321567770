.document {
  color: #453854;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 20px;
  align-items: center;
  background-color: #E8E4ED;
  display: flex;
  border-radius: 5px;

  svg {
    color: currentColor;
    height: 25px;
    flex-shrink: 0;
  }

  div {
    flex-shrink: 0;
    color: currentColor;
    margin-left: 12px;
    width: calc(100% - 15px - 12px);
    white-space: break-spaces;
  }
}