.bdMaskedInput {
  padding: 7px 10px;
  border-radius: 5px;
  display: flex;
  height: 32px;
  box-sizing: border-box;
  color: #453854;
  background-color: #F6F4F8;
  margin: 0;
  outline: 0;
  border: none;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &::placeholder {
    color: rgba(0, 0, 0, 0.25)
  }

  &.error {
    border-color: #ff4d4f;

    &:hover {
      border-color: #ffa39e;
    }
  }

  .label {
    display: block;
    margin-bottom: 10px;
  }

  &.warn {

  }
}