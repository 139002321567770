
.field {
  display: flex;

  .icon {
    width: 30px;
    height: 30px;
    color: #35B879;
    flex-shrink: 0;
  }

  .textContainer {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .title {
    display: inline-block;
    font-size: 12px;
    line-height: normal;
    font-weight: 400;
    color: #453854;
  }

  .message {
    display: inline-block;
    font-size: 16px;
    line-height: normal;
    font-weight: 600;
    color: #453854;
  }
}

