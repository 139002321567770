

.backLink {
  font-size: 15px;
  color: #35B879;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;

  &:visited {
    color: #35B879;
  }
}