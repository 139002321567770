.timer {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  border: 1px solid #CFC8D7;
  color: #CFC8D7;
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 34px;
  flex-shrink: 0;

  &.active {
    border-color: #35B879;
    color: #35B879;
  }
}