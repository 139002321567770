@import "assets/fonts/Gilroy/Gilroy.css";

body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  font-family: Gilroy, sans-serif;
}

button {
  font-family: Gilroy, sans-serif;
}

a {
  text-decoration: none;
  color: currentColor;
  cursor: pointer;

  &:visited {
    color: currentColor;
  }
}

ul {
  margin: 0;
  padding: 0;
}