.company {

  @media (min-width: 960px) {
    display: grid;
    grid-template-columns: fit-content(650px) fit-content(320px);
    gap: 10px;
  }

  .arrow {
    margin-right: 6px;
  }

  .linkBack {
    font-size: 15px;
    color: #35B879;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
  }

  .section {
    margin-top: 28px;
  }
}
