

.notFoundPage {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background-color: #453854;
    padding: 160px 24px;
    min-height: 100vh;

    @media (max-width: 359px) {
        padding: 150px 16px;
    }

    @media (min-width: 980px) {
        min-height: calc(100vh - 161px);
        padding: 20px;
    }

    .container {
        @media (min-width: 980px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: calc(90vh - 161px);
        }
    }

    .content {
        @media (min-width: 980px) {
            max-width: 400px;
            padding: 160px 24% 0;
        }
    }

    .mainInfo {
        border: 2px dashed #DFEB81;
        border-radius: 15px;
        text-align: center;
        padding: 40px 24px;

        @media (min-width: 980px) {
            padding: 80px 40px;
        }
    }

    .title {
        color: #DFEB81;
        font-size: 100px;
        font-weight: 600;
        margin: 0;
    }

    .mainText {
        color: #DFEB81;
        font-size: 20px;
        font-weight: 600;
    }

    .textInfo {
        text-align: center;
        padding-top: 34px;
    }

    .text {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
    }

    .link {
        margin-top: 34px;
    }
}

.footerNotFound {
    background-color: #453854;
    @media (min-width: 980px) {
        padding: 80px 24% 0;
    }
}