.signIn {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background-color: #453854;
  min-height: 100vh;
  padding: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
  }

  .logo {
    height: 24px;
  }

  .close {
    width: 32px;
  }

  .title {
    margin-top: 66px;
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .form {
    margin-top: 17px;
    padding: 15px;
    border-radius: 15px;
    background: #E4DEEA;
  }

  .password {
    margin-top: 12px;
  }

  .submitButton {
    margin-top: 40px;
  }

  .link {
    color: #453854;
    margin: 10px 0 11px;
    display: block;
    text-align: center;
  }

  .firstVisit {
    color: white;
    margin: 35px 0 10px;
    text-align: center;
  }
}