.signUp {

  background-color: #453854;

  @media (min-width: 980px) {
    padding: 80px 24% 0;
  }
}

.modalSnackbar {
  background: #DFEB81;
  width: 100%;
  position: relative;
  border-radius:  0 0 16px 16px;
  padding: 40px 20px;
  box-sizing: border-box;

  &:after{
    content: "";
    position: absolute;
    width: 60px;
    height: 6px;
    background: #DFEB81;
    border-radius: 4px;
    bottom: -10px;
    left: 46%;
    margin-left: -18px;
  }


  .modalSnackbarButton{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #453854;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:before{
      content: '';
      position: absolute;
      width: 16px;
      height: 2px;
      background-color: #DFEB81;
      transform: rotate(45deg);
    }

    &:after{
      content: '';
      position: absolute;
      width: 16px;
      height: 2px;
      background-color: #DFEB81;
      transform: rotate(-45deg);
    }
  }

  .modalSnackbarContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 33px;
  }

  .modalSnackbarHeader{
    color: #453854;
    font-weight: 600;
    font-size: 28px;
  }

  .modalSnackbarFooter {
    color: #453854;
    font-size: 16px;
    margin-top: 11px;
  }

  .modalSnackbarPhone {
    color: #453854;
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    margin: 0;
  }
}