.article {
  display: block;
  background: #F0F0F0;
  border-radius: 10px;
  overflow: hidden;

  .link {
    display: block;
    border-radius: 10px;
    text-decoration: none;

    &:visited {
      color: currentColor;
    }
  }

  .button {
    display: block;
    border: none;
    padding: 0;
    width: 100%;
  }

  .image {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }

  .textInfo {
    padding: 14px 15px 26px;
  }

  .metaInfo {
    display: flex;
    justify-content: space-between;
    color: #999999;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .title {
    margin-top: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: justify;
  }
}