.plusMinusToggle {
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;
  display: none;

  @media (max-width: 959px) {
    display: block;
  }

  &.active {
    &:before {
      transform: translatey(-50%) rotate(-90deg);
      opacity: 0;
    }
    &:after {
      transform: translatey(-50%) rotate(0);
    }
  }

  &:before , &:after {
    content: "";
    display: block;
    background-color: currentColor;
    position: absolute;
    top: 50%; left: 0;
    transition: .35s;
    width: 100%;
    height: 2px;
  }

  &:before {
    transform: translatey(-50%);
  }

  &:after {
    transform: translatey(-50%) rotate(90deg);
  }
}