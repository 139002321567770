.tag {
  display: inline-block;
  padding: 5px 10px;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 10px;
  background-color: #D9D9D9;
}