@use 'src/assets/styles/stub' as *;

.companyInfo {

  .content {
    background-color: #F0F0F0;
    border-radius: 10px;
    padding: 20px 16px;

    @media (min-width: 960px) {
      max-width: 618px;
      min-width: 618px;
    }
  }

  .header {
    display: flex;
    flex-direction: column;

    @media (min-width: 960px) {
      align-items: flex-start;
      flex-direction: row;

    }
  }

  .logo {
    @include stub;

    width: 48px;
    height: 48px;
    object-fit: cover;
  }

  .container {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-top: 15px;

    @media (min-width: 960px) {
      margin-left: 15px;
      margin-top: 8px;
    }
  }

  .name {
    display: inline-block;
    font-size: 24px;
    line-height: 20px;
    font-weight: 600;
    margin: 0;
  }

  .advertiser {
    display: inline-block;
    font-size: 12px;
    line-height: normal;
    font-weight: 400;
    color: #999;
    margin-top: 6px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    @media (min-width: 960px) {
      flex-direction: row;
      margin-top: 42px;
    }
  }

  .fieldSpace {
    margin-top: 15px;
    margin-left: 0;

    @media (min-width: 960px) {
      margin-top: 0;
      margin-left: 35px;
    }
  }

  .fluidContainer {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    margin-top: 30px;

    @media (min-width: 960px) {
      margin-top: 0;
    }
  }

  .button {
    max-width: 100%;

    @media (min-width: 960px) {
      max-width: 150px;
    }
  }
}

.companyInfoStub {
  @include stub;

  height: 314px;
  width: 100%;
  border-radius: 10px;

  @media (min-width: 960px) {
    width: 650px;
    height: 164px;
  }
}

.companyInfoIconStub {
  @include stub;

  width: 48px;
  height: 48px;
}