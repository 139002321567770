.provider {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  border-radius: 10px;
  background: #EBE8E6;
  padding: 15px;
  position: relative;

  @media (min-width: 320px) and (max-width: 360px) {
    min-width: 134px;
    box-sizing: border-box;
    overflow: hidden;
  }

  @media (min-width: 360px) and (max-width: 959px) {
    min-width: 150px;
    box-sizing: border-box;
    overflow: hidden;
  }


  .titleContainer {
    display: block;
  }

  .titleFlexContainer {
    display: flex;
  }

  .smallTitleBlock {
    margin-right: -9px;
  }

  .title {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    word-break: break-word;
    margin-top: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;


    @media (max-width: 359px) {
      font-size: 12px !important;
    }
  }

  .smallText {
    font-size: 13px;
    font-weight: 600;

    @media (max-width: 359px) {
      font-size: 11px !important;
    }
  }

  .smallBlock {
    font-size: 13px;
    font-weight: 600;
    margin-left: -3px;

    @media (max-width: 359px) {
      font-size: 11px !important;
    }
  }

  .logo {
    width: 36px;
    height: 36px;
    margin-right: 9px;
  }

  .tag {
    margin-top: 8px;
    display: inline-block;
  }

  .firstLineProperties {
    margin: 12px 0 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &.sizeS {
      flex-wrap: wrap;
      gap: 5px;
    }
  }

  .button {
    margin-top: 15px;
    pointer-events: all;
  }

  .field1Replaced {
    text-decoration-line: line-through;
  }

  .field1New {
    color: #35B879;
  }

  .advertiser {
    color: #453854;
    text-align: center;
    font-size: 8px;
  }

  .fluidContainer {
    flex-grow: 1;
    position:relative;
    pointer-events: none;
    z-index: 1;
  }

  .spaceContainer {
    margin-top: 4px;
  }

  .advertiserContainer {
    height: 100%;
    max-height: 10px;
    margin-top: 10px;
  }

  .tagContainer {
    height: 100%;
    max-height: 32px;
  }

  .overlay {
    position:absolute;
    left:0;
    top:0;
    bottom:0;
    right:0;
  }
}