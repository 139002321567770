

.seoToggle {
  background-color: transparent;
  height: fit-content;
  color: #453854;
  font-size: 15px;
  border: 1px solid #453854;
  padding: 10px 20px;
  border-radius: 5px;
  transition: color ease-in 150ms;
  cursor: pointer;
  width: 290px;

  &:hover {
    background-color: #453854;
    color: #fff;
  }
}