.phoneStep {

  @media (max-width: 960px) {
    height: 78vh;
  }

  @media (min-width: 960px) {
    display: flex;
    height: 100%;

    & > div {
      flex-basis: 100%;
    }
  }
}

.codeLabel {
  font-size: 14px;
  font-weight: 400;
  color: #A39CAA;
  font-style: normal;
}

.timerGroup {
  margin-top: 17px;
}

.flexContainer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.button {
  width: 100%;
  margin-right: 20px;
}

.hint {
  margin-top: 20px;
  color: #A39CAA;
  border: none;
  text-align: justify;
  font-size: 14px;
  outline: none;
  font-weight: 400;
  background-color: transparent;
  line-height: normal;
  padding: 0;
}

.link {
  border-bottom: 1px solid #A39CAA;
}

.docList {
  margin-top: 10px;

  @media (min-width: 960px) {
    margin-top: 0;
    margin-left: 10px;
  }
}

.docContainer {
  margin-top: 40px;
}

.docListTitle {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.docItem {
  margin-top: 12px;
  list-style-type: none;
}