.mainPage {
  padding: 72px 20px 0;

  @media (min-width: 960px) {
    //padding: 80px 24% 0;
    padding: 80px 0;
    margin: 0 auto;
    max-width: 980px;
  }

  .firsInRefinancing {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 28px;
    border-radius: 10px;
    background: #DFEB81;
    color: #453854;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;

    a {
      width: 290px;
    }
  }

  .subtitle {
    margin-top: 14px;
  }

  .cardStack {
    margin-top: 36px;
    margin-bottom: - (calc(5 * 20px)); // 5 cards shift

    @media (min-width: 960px) {
      margin-bottom: 0;
    }

  }

  .bestOffers {
    margin-top: 40px;
  }

  .magazine {
    margin-top: 40px;
  }

  .oftenLookingFor {
    margin-top: 40px;
  }

  .title {
    margin-top: 16px;

    @media (min-width: 960px) {
      margin-top: 36px;
    }
  }
}