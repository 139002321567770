.footer {
  padding: 20px 20px 19px;
  font-family: Gilroy, sans-serif;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 960px) {
    padding: 0;
    width: 980px;
    margin: auto;
  }

  .container {
    padding: 28px 16px;
    background-color: #453854;
    border-radius: 10px;
  }

  .socials {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > svg {
      height: 24px;
    }

    span {
      display: flex;
      gap: 16px;

      svg {
        width: 32px;
      }
    }
  }

  .copyrights {
    margin-top: 25px;
    color: #A39CAA;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .mail {
      color: #A39CAA;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }
  }


  nav {
    margin-top: 25px;
    padding: 25px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 19px;
    border-bottom: solid white 1px;
    border-top: solid white 1px;
  }

  a {
    color: white;
    text-decoration: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:visited {
      color: white;
    }
  }
}