.headerLayout {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: transparent;
  width: 100%;


  @media (min-width: 960px) {
    background-color: white;
    padding: 0;
    margin: auto;
    border-bottom: 0.5px solid #999999;
    height: 80px;
  }
}

.dark {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: transparent;
  width: 100%;

  @media (min-width: 960px) {
    background-color: #453854;
    padding: 0;
    margin: auto;
    border-bottom: 0.5px solid #999999;
    height: 80px;
  }
}

.header {
  padding: 25px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1024px) {
    padding: 0;
    margin: auto;
    height: 80px;
    width: 980px;
  }

  .logo {
    flex-shrink: 0;
    color: #453854;
    height: 24px;
  }

  .darkLogo {
    color: #fff !important;
  }

  .user {
    height: 32px;
    width: 32px;
    color: #453854;
  }

  .anchors {
    a:not(:last-child) {
      margin-right: 50px;
    }

  }

  .darkAnchors {
    color: #fff !important;
  }

  .button {
    flex-shrink: 0;
    width: 140px;
  }

  .dropdown {
    position: fixed;
    top: 82px;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(69, 56, 84, 0.5);

    @media (max-width: 960px) {
      top: 0;
    }
  }

  .burgerButton {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  .stubContainer {
    flex-shrink: 0;
    width: 140px;

    @media (max-width: 960px) {
      width: 32px;
    }
  }

}