.signUpFooter {
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  border-top: 1px solid #fff;
  margin: auto;
  height: 80px;


  @media (max-width: 980px) {
    display: none;
  }
}

.socialsContainer {
  display: flex;

  svg {
    height: 32px;
    flex-shrink: 0;
  }

  a:not(:last-child) {
    margin-right: 12px;
  }
}