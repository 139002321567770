.budgettBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 15px;
  border-radius: 10px;
  background: linear-gradient(79deg, #1C2A40 28.4%, #929AB2 143.79%);
  color: #FFFFFF;

  .logo {
    width: 48px;
    height: 48px;
    color: #F2F4F9;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .title {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    align-items: center;
    color: #FFFFFF;
    margin-left: 10px;

    @media (max-width: 960px) {
      font-size: 14px;
    }
  }

  .text {
    margin-left: 10px;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    align-items: center;
    color: #FFFFFF;

    @media (max-width: 960px) {
      font-size: 10px;
    }
  }

  .arrowContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: #FFFFFF;
    height: 32px;
    width: 32px;
    flex-shrink: 0;

    svg {
      flex-shrink: 0;
      color: black;
      width: 12px;
    }

    @media (max-width: 360px) {
      display: none;
    }
  }

  .firstContainer {
    display: flex;
  }
}