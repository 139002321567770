
.fileField {
  color: #A39CAA;
  transition: color ease-out 50ms;
  margin-top: 11px;

  .link {
    display: flex;
    align-items: center;
    transition: color ease-out 50ms;

    &:hover {
      color: #453854;
    }
  }

  .icon {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 10px;
    margin-left: 13px;
  }
}
