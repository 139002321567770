.input {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  .label {
    display: block;
    margin-bottom: 10px;
  }

  .nativeInputContainer {
    padding: 7px 10px;
    border-radius: 5px;
    background-color: #F6F4F8;
    display: flex;
    height: 32px;
    box-sizing: border-box;
  }

  .nativeInput {
    color: #453854;
    background-color: #F6F4F8;
    margin: 0;
    padding: 0;
    outline: 0;
    border: none;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    font-size: 16px;
  }

  .suffix {
    width: 16px;
    height: 16px;
    max-width: 16px;
    max-height: 16px;
    color: #CFC8D7;
    transition: all .3s;
  }

  .clickable {
    cursor: pointer;

    &:hover {
      color: #453854;
    }
  }

  .prefix {
    width: 35px;
  }
}