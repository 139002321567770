@use 'src/assets/styles/stub' as *;

.about {
  padding: 20px 16px 30px;

  .title {
    font-size: 16px;
    line-height: normal;
    font-weight: 600;
    margin: 0;
  }

  .filesContainer {
    margin-top: 30px;
  }

  .files {
    margin-top: 2px;
  }
}

.companyAboutStub {
  @include stub;

  border-radius: 10px;
  width: 100%;
  height: 387px;

  @media (min-width: 960px) {
    width: 650px;
    height: 384px;
  }
}
