.linkButton {
  display: inline-block;
  width: 100%;
  border-radius: 6px;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  border: none;
  text-decoration: none;
  text-align: center;
  transition: background-color .3s, color .3s;

  &.primary {
    background-color: #FFF;
    color: #000;

    &:visited {
      color: #000;
    }

    &:hover {
      color: #FFFFFF;
      background-color: #453854;
    }
  }

  &.secondary {
    background-color: #453854;
    color: #FFFFFF;

    &:visited {
      color: #FFFFFF;
    }

    &:hover {
      color: #FFFFFF;
      background-color: #665A73;
    }
  }

  &.green {
    background-color: #35B879;
    color: #FFFFFF;

    &:visited {
      color: #FFFFFF;
    }

    &:hover {
      color: #FFFFFF;
      background-color: #54D195;
    }
  }

  &.yellow {
    background-color: #DFEB81;
    color: #453854;

    &:visited {
      color: #453854;
    }

    &:hover {
      color: #453854;
      background-color: #E4ED9B;
    }
  }

  &.thick {
    //height: 50px;
    line-height: 50px;
  }

  &.thin {
    height: 36px;
    line-height: 36px;
  }
}