.propertyBlock {
  display: flex;
  flex-direction: column;
  font-style: normal;
  line-height: normal;

  .title {
    font-size: 14px;
    font-weight: 400;
  }

  .text {
    font-size: 18px;
    font-weight: 600;

    @media (min-width: 960px) {
      font-size: 14px;
    }

    &.small {
      font-size: 14px;
    }
  }
}