.article {
  display: flex;
  background: transparent;
  overflow: hidden;

  .link {
    display: block;
    text-decoration: none;

    &:visited {
      color: transparent;
    }
  }

  .container {
    display: flex;
  }

  .button {
    display: block;
    border: none;
    padding: 0;
    width: 100%;
  }

  .image {
    width: 100px;
    height: 130px;
    object-fit: cover;
    border-radius: 5px;
  }

  .textInfo {
    padding: 0 10px 16px;
  }

  .metaInfo {
    display: flex;
    color: #999999;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
    margin-top: 8px;

    .space {
      margin-left: 10px;
    }
  }

  .title {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}