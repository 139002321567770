@use 'src/assets/styles/stub' as *;

.details {
  padding: 20px 16px;

  .title {
    font-size: 16px;
    line-height: normal;
    font-weight: 600;
    margin: 0;
  }

  .content {
    display: flex;
    flex: 0 1 50%;
    flex-wrap: wrap;
    gap: 0;

    @media (min-width: 960px) {
      gap: 40px;
      flex-wrap: nowrap;
    }
  }

  .column {
    flex: 0 1 100%;

    @media (min-width: 960px) {
      flex: 0 1 50%;
    }
  }

  .buttonContainer {
    margin-top: 20px;
    max-width: 100%;
  }

  .button {
    transition: color ease-out 50ms;
    flex-shrink: 0;
    min-width: 100px;

    @media (min-width: 960px) {
      max-width: 290px;
    }
  }

  .fluidContainerButtons {
    display: none;
    @media (min-width: 960px) {
      display: block;
      width: 100%;
      max-width: 290px;
    }
  }

  .secondaryButton {
    display: none;
    background-color: #35B879;

    &:hover {
      background-color: #54D195;
    }

    @media (min-width: 960px) {
      display: block;
    }
  }
}

.companyDetailsStub {
  @include stub;

  border-radius: 10px;
  width: 100%;
  height: 638px;

  @media (min-width: 960px) {
    width: 650px;
    height: 374px;
  }
}
