.stepper {
  height: 80px;
  padding: 14px 30px 18px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #453854;
  z-index: 9;


  @media (min-width: 960px) {
    top:0;
    bottom: unset;
    padding-left: calc((100% - 960px) / 2);
    padding-right: calc((100% - 960px) / 2);
    align-items: center;
  }
}

.logo {
  height: 24px;
  color: #453854;
}

.dotsContainer {
  display: flex;
  gap: 7px;
  padding: 12px 0;
  color: #EC2351;
  text-align: center;
  font-size: 14px;
  line-height: normal;
}

.item {
  background-color: #E8E4ED;
  width: 8px;
  height: 8px;
  border-radius: 50%;

  &.filled {
    @keyframes riseUp {
      0% {
        background-color: #E8E4ED;
      }

      100% {
        background-color: #453854;
      }
    }

    animation-name: riseUp;
    animation-duration: .3s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-play-state: running;
    background-color: #453854;
  }
}

.nextButton {
  height: 32px;
  width: 140px;
}

.topBlockWrapper{
  @media (max-width: 1080px  ){
    background: #fff;
    width: 100%;
    position: relative;
    border-radius: 16px 16px 0 0;
    padding: 41px 72px 47px 72px;
    box-sizing: border-box;
  }

  @media (max-width: 720px) {
    padding: 19px 23px 17px 23px;
  }

  &:before{
    content: '';
    position: absolute;
    width: 37px;
    height: 4px;
    background: #EBEEF5;
    border-radius: 4px;
    top: -10px;
    left: 50%;
    margin-left: -18px;
  }
}

.topInfoWrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1080px  ){
    margin-bottom: 41px;
  }
  @media (max-width: 720px) {
    margin-bottom: 19px;
  }
}

.topBlockHeader{
  color: #1c2a40;
  font-weight: 600;
  font-size: 18px;
}

.closeInfoButton{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #453854;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before{
    content: '';
    position: absolute;
    width: 16px;
    height: 2px;
    background-color: #ffffff;
    transform: rotate(45deg);
  }

  &:after{
    content: '';
    position: absolute;
    width: 16px;
    height: 2px;
    background-color: #ffffff;
    transform: rotate(-45deg);
  }
}

.bottomInfoWrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.contactItem{
  text-align: center;

    p{
      margin-top: 9px;
      font-size: 11px;
      font-weight: 700;
      color: #453854;
    }

    svg{
      color: #453854;
      width: 40px;
      height: 40px;
    }
}