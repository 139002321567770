@use 'src/assets/styles/stub' as *;

.companies {
  padding: 80px 20px 0;

  @media (min-width: 960px) {
    padding: 108px 0;
    margin: 0 auto;
    max-width: 980px;
  }

  .bestOffers {
    margin-top: 40px;
  }


  .content {


    @media (min-width: 960px) {
      display: grid;
      grid-template-columns: fit-content(650px) fit-content(320px);
      gap: 10px;
    }
  }


  .infoBlock {
    background-color: #F0F0F0;
    border-radius: 10px;
    margin-top: 28px;
    padding: 20px 16px;
  }

  .infoBlockTitle {
    color: #000000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }

  .infoBlockText {
    color: #000000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 16px;
  }

  .oftenLookingFor {
    margin-top: 40px;
  }

  .companyTable {
    margin-top: 10px;
    background-color: #F0F0F0;
    padding: 0 16px;
    border-radius: 10px;

    @media (min-width: 960px) {
      max-width: 618px;
      min-width: 618px;
    }
  }

  .row {
    align-items: center;
    background-color: #F0F0F0;
    justify-content: flex-start;
    display: flex;
    padding: 16px 0;
    position: relative;

    &:not(:last-child) {
      border-bottom: 1px solid #fff;
    }

    &:last-child {
      margin-bottom: 16px;
    }
  }

  .cell {
    margin-right: 10px;
  }

  .cellNumber {
    width: 20px;
  }
  .cellName {
    width: 100%;
    max-width: 174px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    @media (max-width: 960px) {
      -webkit-line-clamp: 3;
    }

    @media (max-width: 359px) {
      -webkit-line-clamp: 1;
    }
  }

  .mr0 {
    margin-right: 0;
  }

  .space {
    max-width: 220px;
  }

  .cellLogo {
    width: 36px;
    height: 36px;
    margin-left: 10px;
  }

  .cellRating {
    width: 100%;
    max-width: 100px;

    @media (max-width: 960px) {
      max-width: 68px;
    }
  }

  .cellInfo {
    width: 100%;
    max-width: 68px;
    text-align: start;
  }

  .greenCell {
    color: #35B879;
    text-align: start;
  }

  .rowHead {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    padding-top: 20px;
  }

  .rowContent {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
  }

  .rowLink {
    cursor: pointer;
  }


  .stub {
    @include stub;

    height: 51px;
    margin-top: 10px;
    padding: 0 16px;
    border-radius: 10px;

    &:last-child {
      margin-bottom: 16px;
    }
  }

  .stubImage {
    @include stub;
    height: 36px;
    width: 36px;
    border-radius: 10px;
  }

  .spinnerBlock {
    display: flex;
    align-content: center;
    justify-content: center;
    height: 80px;

  }

  .spinner {
    width: 60px;
    height: 60px;
    color: #35B879;
  }

  .ratingBlock {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }

  .rating {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: flex-end;

  }

  .star {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-left: 4px;
  }

  .total {
    color: #999999;
    font-size: 12px;
    font-weight: 400;
    margin-top: 2px;
  }
}