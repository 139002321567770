
.companyTabs {
  .content {
    background-color: #F0F0F0;
    border-radius: 10px;
    margin-top: 20px;

    @media (min-width: 960px) {
      max-width: 650px;
      min-width: 650px;
    }
  }
}
