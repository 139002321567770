.navMenuContainer {
  max-height: 80vh;
  transition: all 150ms;
}

.navMenu {
  box-sizing: border-box;
  background-color: #FFFFFF;
  padding: 0 24%;
  height: 100%;
  border-radius: 0 0 15px 15px;
  overflow: hidden;
  display: flex;
  transition: all 150ms;

  @media (max-width: 960px) {
    padding: 30px 35px;
    overflow: auto;
    flex-direction: column;
  }

  .mainMenu {
    padding: 40px 0;

    @media (max-width: 960px) {
      padding: 0;
      width: 100%;
    }

    li {
      list-style-type: none;
    }

    li:not(:first-child) {
      margin-top: 20px;
    }

    li:last-child {
      padding-bottom: 30px;

      &.active {
        padding-bottom: 0;
      }
    }

    .productItemList {
      margin-top: 12px;

      li:last-child {
        padding-bottom: 0;
      }
    }

  }

  .mainMenuLink {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: color .3s;

    &:hover {
      color: #35B879;
    }
  }

  .submenu {
    margin-left: 176px;
    padding-top: 35px;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 36px 36px 36px 36px 36px;
    grid-template-columns: max-content max-content;
    column-gap: 55px;
    row-gap: 8px;
  }
}

.dropdownContainer {
  height: 0;
  overflow: hidden;
  transition: all .3s;

  &.open {
    height: auto;
  }
}

.mobileMenuItem {
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  color: currentColor;

  .title {
    flex-shrink: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
  }

  .icon {
    width: 14px;
    flex-shrink: 0;
  }
}

.dragger {
  width: 90px;
  height: 6px;
  border-radius: 3px;
  background: #FFF;
  position: relative;
  top: 10px;
  left: calc(50% - 45px);
}

.productItem {
  --color: currentColor;
  --hover-color: currentColor;

  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--color);

  .link {
    border: 1px solid #000;
  }

  &.small {
    .iconContainer {
      width: 36px;
      height: 36px;
      margin-right: 12px;
    }

    .icon {
      width: 20px;
      height: 20px;
    }
  }
}