@use 'src/assets/styles/stub' as *;

.bestOffers {
  .title {
    padding-left: 15px;
    margin-bottom: 10px;

    @media (min-width: 960px) {
      padding-left: 0;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .cards {
    margin-top: 20px;
    gap: 10px;
    overflow: hidden;
  }

  .flexContainer {
    display: flex;
    gap: 10px;
  }

  .offerContainer {
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    height: 317px;

    @media (max-width: 960px) {
      height: 250px;
    }

    @media (max-width: 359px) {
      height: 225px;
    }
  }

  .stub {
    @include stub;

    flex-shrink: 0;
    flex-basis: 315px;
    width: 315px;
    height: 254px;
    border-radius: 10px;
  }
}