
.aboutCompany {
  padding: 80px 20px 10px;

  @media (min-width: 960px) {
    padding: 108px 0 30px;
    margin: 0 auto;
    max-width: 980px;
  }

  .aboutBanner {
    background-color: #453854;
    border-radius: 10px;
    margin-top: 23px;
    padding: 30px 16px;

    @media (min-width: 960px) {
      margin-top: 28px;
    }


    .title {
      color: #DFEB81;
      font-size: 20px;
      font-weight: 600;
      margin-top: 14px;

      @media (min-width: 960px) {
        font-size: 24px;
        margin-top: 10px;
      }
    }

    .text {
      color: #fff;
      margin-top: 21px;
      font-size: 16px;
      font-weight: 400;

      @media (min-width: 960px) {
        font-size: 18px;
        margin-top: 20px;
      }
    }

    .logo {
      width: 182px;
      height: 30px;
      margin-top: 10px;
      flex-shrink: 0;

      @media (min-width: 960px) {
        width: 244px;
        height: 40px;
      }
    }
  }

  .cardSection {
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;

    @media (min-width: 960px) {
      flex-wrap: nowrap;
      margin-top: 30px;
      gap: 10px;
    }
  }

  .history {
    background-color: #F0F0F0;
    border-radius: 10px;
    padding: 20px 16px 30px 16px;
    margin-top: 20px;

    @media (min-width: 960px) {
      margin-top: 30px;
    }

    .title {
      color: #000;
      font-size: 24px;
      font-weight: 600;
    }

    .text {
      color: #000;
      font-size: 16px;
      font-weight: 400;
      margin-top: 10px;

      @media (min-width: 960px) {
        margin-top: 13px;
      }
    }
  }

  .contacts {
    background-color: #F0F0F0;
    border-radius: 10px;
    padding: 20px 16px 30px 16px;
    margin-top: 20px;

    @media (min-width: 960px) {
      margin-top: 30px;
    }

    .title {
      color: #000;
      font-size: 24px;
      font-weight: 600;
    }
    .container {
      display: flex;
      flex-direction: column;
    }

    .row {
      display: flex;
      flex-wrap: wrap;

      @media (min-width: 960px) {
        flex-wrap: nowrap;
      }
    }
  }
}