.productItem {
  --color: currentColor;
  --hover-color: currentColor;

  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--color);

  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 6px;
    margin-right: 16px;
    flex-shrink: 0;
  }

  .icon {
    width: 26px;
    height: 26px;
  }

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color .3s;
    color: var(--color);

    &:hover {
      color: var(--hover-color);
    }
  }

  &.small {
    .iconContainer {
      width: 36px;
      height: 36px;
      margin-right: 12px;
    }

    .icon {
      width: 20px;
      height: 20px;
    }
  }
}