.suggestInput {
  position: relative;

  .input {
    display: block;
    width: 100%;
  }

  .label {
    display: block;
    margin-bottom: 10px;
    margin-top: 12px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.suggestBox{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  background-color: #F6F4F8;
  z-index: 100;
  color: #453854;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: none;
  max-height: 200px;
  overflow-y: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  &.open {
    display: block;
  }

  .suggestItem {
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color .3s;

    &:hover {
      background-color: #665A73;
      color: #fff;
    }
  }
}