.tabs {
  display: flex;
  width: 100%;
  overflow-x: auto;

  .tab {
    flex-shrink: 0;
    display: inline-block;
    padding: 4px 10px;
    height: 26px;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    box-sizing: border-box;
    text-decoration: none;
    border-radius: 13px;
    background-color: #FFFFFF;
    transition: background-color 0.3s;
    border: none;
    cursor: pointer;



    &.selected {
      background-color: #DFEB81;
    }
  }
}