
.preloader {
  background-color: #fff;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9;
  opacity: 0.4;


  .container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .spinner {
    width: 80px;
    height: 80px;
  }
}