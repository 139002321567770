.Subtitle {
  margin: 0;
  color: #453854;
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;

  @media(min-width: 960px) {
    font-size: 20px;
  }

  .Mark {
    background-color: #DFEB81;
    color: currentColor;
  }
}