
.promoContent {
  padding: 80px 20px 30px;

  @media (min-width: 960px) {
    padding: 108px 0 30px;
    margin: 0 auto;
    max-width: 980px;
  }

  .info {
    background-color: #F0F0F0;
    border-radius: 10px;
    padding: 20px 16px;
    margin-top: 28px;
  }

  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    padding: 0;
    margin: 0;
  }

  .text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-top: 10px;
  }

  .subtitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 0;
  }

  .block {
    padding: 0 16px;
  }

  .list {
    list-style-type: disc;
  }

  ol {
    list-style: none;
    counter-reset: li;
    padding: 0;
    margin: 0;
  }

  .link {
    color: #35B879;
    cursor: pointer;

    &:visited {
      color: #35B879;
    }
  }
}