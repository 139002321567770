.title {
  margin: 0;
  color: #000;
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (min-width: 960px) {
    font-size: 36px;
    font-weight: 700;
  }
}