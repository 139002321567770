
.splide {
  &__pagination {
    position: relative !important;
    padding: 10px;
    bottom: 0;

    &__page {
      .is-active {
      }
    }
  }
}

.splide__pagination__page.is-active {
  background-color: #453854 !important;
}
