@use 'src/assets/styles/stub' as *;

.bankruptcy {
  background-color: #D8D5D3;
  margin: 0;
  padding:  77px 20px 0;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 960px) {
    width: 980px;
    margin: 108px auto;
    padding: 0;
  }

  .title {
    @media (min-width: 960px) {
      background-color: #CBC7C4;
      color: #453854;
    }
  }

  ul {
    margin: 15px 0 0;
    padding: 0;

    @media (min-width: 960px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;
    }

    & > *:not(:first-child) {
      margin-top: 10px;

      @media (min-width: 960px) {
        margin-top: 0;
      }
    }

    .banner {
      grid-column: 1 / 3;
    }
  }

  .stub {
    @include stub;

    flex-shrink: 0;
    flex-basis: 316px;
    width: 316px;
    height: 292px;
    border-radius: 10px;

    @media (max-width: 960px) {
      min-width: 155px;
      width: 100%;
    }
  }

  .stubBunner {
    @include stub;

    width: 100%;
    height: 80px;
    border-radius: 10px;
  }
}