.cardStack {
  .cardContainer {
    @media (max-width: 959px) {

      details:last-child {
        padding-bottom: 24px;
      }

      details[open]:last-child {
        padding-bottom: 12px;
      }
    }

    @media (min-width: 960px) {
      margin-top: 35px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
    }
  }

  .title {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

}