.subInfoStep {
  height: 78vh;

  @media (min-width: 960px) {
    width: 320px;
    height: 100%;
  }

    .row {
      display: flex;
      align-items: center;
      margin-top: 12px;
    }

  .space {
    margin-right: 12px;
  }
}
