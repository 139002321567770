.roundButton {
  background-color: transparent;
  padding: 0;
  height: fit-content;
  border: none;

  &.default {
    .circle {
      background-color: #453854;
    }

    .description {
      color: #453854;
    }
  }

  &.success {
    .circle {
      background-color: #35B879;
    }

    .description {
      color: #35B879;
    }
  }

  &:disabled {
    .circle {
      background-color: #A39CAA;
    }

    .description {
      color: #A39CAA;
    }
  }
}

.circle {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: auto;
  color: white;
  transition: background-color .3s;
}

.description {
  margin-top: 3px;
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  line-height: normal;
  transition: color .3s;
}