.container {
  padding: 40px 20px 94px;
  border-radius: 15px;
  background: #fff;
  height: 100%;
  max-width: 100%;

  @media (min-width: 960px) {
    padding: 40px 20px;
    height: 100%;
  }
}