.step {
  background-color: #453854;
  min-height: 100vh;
  padding: 0 0 20px;
  box-sizing: border-box;


  @media (min-width: 960px) {
    min-height: calc(100vh - 161px);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}