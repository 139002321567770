
.provider {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  border-radius: 10px;
  background: #EBE8E6;
  padding: 15px;
  position: relative;


  @media (max-width: 359px) {
    padding: 10px;
  }

  .overlay {
    position:absolute;
    left:0;
    top:0;
    bottom:0;
    right:0;
  }

  .titleContainer {
    display: flex;
    align-items: center;
  }

  .title {
    font-size: 14px;
    font-weight: 600;


    @media (max-width: 359px) {
      font-size: 12px !important;
    }
  }

  .smallText {
    font-size: 12px;
    font-weight: 600;

    @media (max-width: 359px) {
      font-size: 11px !important;
    }
  }

  .logo {
    width: 36px;
    height: 36px;
    margin-right: 9px;
    flex-shrink: 0;


    @media (max-width: 359px) {
      width: 32px;
      height: 32px;
    }
  }

  .tagContainer {
    height: 100%;
    max-height: 32px;
  }

  .tag {
    margin-top: 6px;
    display: inline-block;
  }

  .fluidContainer {
    flex-grow: 1;
    position:relative;
    pointer-events: none;
    z-index: 1;
    margin-top: 10px;
  }

  .field1Replaced {
    text-decoration-line: line-through;
  }

  .field1New {
    color: #35B879;
  }

  .firstLineProperties {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    //&.sizeS {
    //  flex-wrap: wrap;
    //  gap: 5px;
    //}
  }

  .button {
    pointer-events: all;
    font-size: 13px;
    margin-top: 12px;
  }

  .advertiser {
    color: #453854;
    text-align: center;
    font-size: 8px;
    margin-top: 9px;
  }
}

.propertyBlock {
  display: flex;
  flex-direction: row;
  font-style: normal;
  line-height: normal;
  align-items: center;
  margin-top: 4px;
  flex-wrap: wrap;

  .title {
    flex-shrink: 0;
    font-size: 12px;
    font-weight: 500;
    margin-right: 2px;

    @media (max-width: 359px) {
      margin-right: 2px;
      font-size: 11px !important;
    }

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .text {
    flex-shrink: 0;
    font-size: 12px;
    font-weight: 600;

    @media (max-width: 359px) {
      font-size: 11px !important;
    }
  }
}