$n: 10;

.details {
  @media (max-width: 959px) {
    @for $i from 0 through $n {
      &:nth-child(#{$i}) {
        transform: translateY($i * -26px);
      }
    }

    &:last-child {
      padding-bottom: 24px;
    }
  }

  @media (min-width: 960px) {
    &:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
    }
    &:nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
    }
    &:nth-child(3) {
      grid-area: 1 / 2 / 3 / 3;
    }
    &:nth-child(4) {
      grid-area: 1 / 3 / 2 / 4;
    }
    &:nth-child(5) {
      grid-area: 2 / 3 / 3 / 4;
    }
  }

  border-radius: 16px;
  padding: 24px 16px 30px;

  @media (min-width: 960px) {
    padding-bottom: 20px;
  }

  .summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Gilroy, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    list-style: none;

    &::-webkit-details-marker {
      display:none;
    }
  }

  .plus {
    height: 16px;
    width: 16px;
  }

  .subtitle {
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .footerButton {
    margin-bottom: 12px;

    @media (min-width: 960px) {
      margin-bottom: 0;
    }
  }


  .content {
    transition: all .3s;
    height: 0;
    overflow: hidden;

    @media (min-width: 960px) {
      height: auto;
    }

    li:last-child {
      padding-bottom: 24px;
    }
  }
}
