
.seoInfoBlock {
  background-color: #F0F0F0;
  border-radius: 10px;
  padding: 20px 16px 30px 16px;
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  transition: opacity ease-out 150ms;

  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-top: 10px;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .fullTextContainer {
    margin-top: 20px;
  }

  .flexInfoBlock {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .list {
    list-style-type: disc;
  }

  ol {
    list-style: none;
    counter-reset: li;
    padding: 0;
    margin: 0;
  }

  .block {
    padding: 0 16px;
  }

  .show {
    transition: height linear 150ms;
    pointer-events: all;
    display: inline-block;
    height: 100%;
  }

  .hide {
    transition: height linear 150ms;
    display: none;
    pointer-events: none;
    height: 0;
  }
}