.button {
  display: inline-block;
  width: 100%;
  border-radius: 6px;
  height: 32px;
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  transition: background-color .3s, color .3s;
  cursor: pointer;

  &.primary {
    height: 50px;
    background-color: #FFF;
    color: #000;

    &:hover {
      color: #FFFFFF;
      background-color: #453854;
    }
  }

  &.secondary {
    background-color: #453854;
    color: #FFFFFF;

    &:hover {
      color: #FFFFFF;
      background-color: #665A73;
    }
  }

  &.green {
    background-color: #35B879;
    color: #FFFFFF;

    &:hover {
      color: #FFFFFF;
      background-color: #54D195;
    }
  }

  &.yellow {
    background-color: #DFEB81;
    color: #453854;

    &:hover {
      color: #453854;
      background-color: #E4ED9B;
    }
  }

  &.thick {
    height: 50px;
    line-height: 50px;
  }

  &.thin {
    height: 36px;
    line-height: 36px;
  }

  &:disabled {
    background-color: #CFC8D7;

    &:hover {
      background-color: #CFC8D7;
    }
  }
}
