.oftenLookingFor{
  .title {
    padding-left: 15px;
    margin-bottom: 10px;

    @media (min-width: 960px) {
      margin-bottom: 20px;
      padding-left: 0;
      font-size: 36px;
      font-weight: 700;
    }
  }

  .cards {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    margin-top: 20px;

    @media (min-width: 960px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      overflow-x: auto;
    }
  }
}