.fullNameStep {
  display: flex;
  flex-direction: row;
  gap: 40px;

  @media (max-width: 960px) {
    gap: 0;
    flex-direction: column;
  }



  .item {
    width: 50%;

    & > *:not(:first-child) {
      margin-top: 12px;
    }

    @media (max-width: 960px) {
      width: auto;

    }
  }

  .row {
    display: flex;
    align-items: center;
    margin-top: 12px;
  }

  .space {
    margin-right: 10px;
  }

  .label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}