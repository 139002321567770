@use 'src/assets/styles/stub' as *;

.account {
  padding: 20px 16px 30px;
}

.companyAccountStub {
  @include stub;

  border-radius: 10px;
  width: 100%;
  height: 384px;

  @media (min-width: 960px) {
    width: 650px;
    height: 384px;
  }
}
